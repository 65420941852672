<template>
  <div>
    <v-row class="fld">
      <v-col md="7">
        <div class="d-flex align-center fld">
          <h3 class="font-family-raleway-medium">
            {{ $t("billing_page.breakdown") }}
          </h3>
          <v-btn
            class="ml-2 box-shadow-none text-none"
            v-if="!isEditBilling"
            @click="addNewPaymentMethod"
            color="primary"
            text
            small
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span class="font-family-raleway-medium">{{
              $t("billing_page.add_new_payment")
            }}</span>
          </v-btn>
        </div>
        <p class="pt-5 font-raleway-regular">
          {{ $t("billing_page.billing_breakdown_description") }}
        </p>
      </v-col>
      <v-col md="5" class="mb-5">
        <span
          class="color-red font-raleway-regular ml-10"
          v-if="isHaveWarningText && !isEditBilling"
          >{{ $t("billing_page.haveto_add_payment_text") }}</span
        >
        <div
          v-for="(stripePaymentMethod, indx) of stripePaymentMethods"
          :key="indx"
          class="d-flex justify-space-between mb-2"
        >
          <div class="d-flex align-center">
            <img :src="cardIcon" alt="" />
            <span class="mx-5 font-family-raleway-bold">{{
              stripePaymentMethod.number
            }}</span>
            <span class="font-weight-bold text-uppercase color-purple">{{
              stripePaymentMethod.cardBrand
            }}</span>
          </div>
          <div class="default-box" v-if="stripePaymentMethod.default">
            {{ $t("default") }}
          </div>
          <v-btn
            v-else
            class="font-family-raleway-bold text-none mb-1"
            @click="openDialog(stripePaymentMethod.pM_ID)"
            :loading="loading"
            color="primary"
            height="32"
            outlined
            small
            text
          >
            {{ $t("make_default") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row :class="isEditBilling ? 'd-flex fld' : 'd-none'">
      <v-col md="4" class="payment-method">
        <div
          class="stripe-card"
          :class="!isEditBilling ? 'disable-card bg-textField' : 'border'"
        >
          <div id="cardNumber" />
        </div>
      </v-col>
      <v-col md="1">
        <div
          class="stripe-card"
          id="cardExpire"
          :class="!isEditBilling ? 'disable-card bg-textField' : 'border'"
        />
      </v-col>
      <v-col md="1">
        <div
          class="stripe-card"
          id="cardCVC"
          :class="!isEditBilling ? 'disable-card bg-textField' : 'border'"
        />
      </v-col>
      <v-col md="6" class="text-right payment-method" v-if="isEditBilling">
        <div class="d-flex float-right align-center" style="width: 100%">
          <v-btn
            class="font-family-raleway-medium box-shadow-none mr-10 text-decoration-underline text-capitalize"
            style="background-color: #fff !important"
            @click="handleCancel"
          >
            {{ $t("billing_page.discard") }}
          </v-btn>
          <v-btn
            class="font-family-raleway-medium bg-primary color-white border-16"
            height="45"
            @click="handleSave"
            :loading="loading"
          >
            <strong>{{ $t("billing_page.save_payment_button") }}</strong>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <make-sure-default-card
      :dialog="dialog"
      :isMakingDefault="isMakingDefault"
      @cancel="dialog = false"
      @makeDefault="makeDefault"
    />
  </div>
</template>

<script>
import { authService } from "@/services/auth";
import cardIcon from "@/assets/img/card.svg";
import { mapGetters } from "vuex";

export default {
  name: "BillingInfosmationSection",
  components: {
    MakeSureDefaultCard: () => import("@/components/dialogs/MakeSureDefaultCard")
  },
  props: {
    stripePaymentMethods: Array,
    isHaveWarningText: Boolean,
  },
  data() {
    return {
      loading: false,
      isEditBilling: false,
      isMakingDefault: false,
      cardIcon,
      dialog: false,
      paymentMethodId: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  mounted() {
    this.setUpStripe();
  },
  methods: {
    addNewPaymentMethod() {
      this.$emit("disableWarningText");
      this.isEditBilling = true;
    },
    setUpStripe: function () {
      const apiKey = process.env.VUE_APP_STRIPE_KEY;
      let stripe = window.Stripe(apiKey);
      this.stripe = stripe;
      this.elements = stripe.elements();
      this.cardNumber = this.elements.create("cardNumber", {
        showIcon: true,
        placeholder: "Card number",
        style: {
          base: {
            backgroundColor: "transparent",
            iconColor: "#262626",
            fontFamily: "Raleway medium, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
          },
          invalid: {
            iconColor: "Red",
            color: "Red",
          },
        },
      });
      this.cardExpiry = this.elements.create("cardExpiry");
      this.cardCvc = this.elements.create("cardCvc");
      this.cardNumber.mount("#cardNumber");
      this.cardExpiry.mount("#cardExpire");
      this.cardCvc.mount("#cardCVC");
    },
    async handleSave() {
      this.loading = true;
      try {
        const response = await this.purchase();
        if (response === "error") return;
        const methodId = response;
        const data = await authService.attachMethods(methodId);
        if (data.status === "requires_action") {
          var confirmResult = await this.stripe.confirmCardPayment(
            data.data.clientSecret
          );
          if (confirmResult.error) {
            //ERROR
            console.log(
              "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
            );
          } else {
            //SUCCESS
            this.paymentMethodId = methodId;
            this.handleCancel();
            this.$notify({
              group: "foo",
              type: "success",
              title: "Card was successfully changed!",
            });
            this.$emit("setDefaultPayments");
          }
        } else {
          this.paymentMethodId = methodId;
          this.handleCancel();
          this.$notify({
            group: "foo",
            type: "success",
            title: "Card was successfully changed!",
          });
          this.$emit("setDefaultPayments");
        }
        if (data.error) {
          this.$notify({
            group: "foo",
            title: data.error.message || "Something went wrong!",
            type: "error",
          });
          this.loading = false;
          return "error";
        }
      } catch (err) {
        const errCode = err.response?.statusText;
        this.$notify({
          group: "foo",
          type: "error",
          title: errCode ? errCode : "Something went wrong",
        });
      }
      this.loading = false;
    },
    async makeDefault() {
      try {
        this.isMakingDefault = true;
        await authService.setDefaultMethod(this.paymentMethodId);
        this.$emit("setDefaultPayments");
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully changed!",
        });
        this.isMakingDefault = false;
      } catch (err) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Something went wrong!",
        });
      }
      this.dialog = false;
    },
    purchase: async function () {
      const result = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.cardNumber,
        billing_details: {
          email: this.getUser ? this.getUser.email : "",
        },
      });
      if (result.status === "requires_action") {
        var confirmResult = await this.stripe.confirmCardPayment(
          result.data.clientSecret
        );

        if (confirmResult.error) {
          //ERROR
          console.log(
            "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
          );
        } else {
          //SUCCESS
          if (result.paymentMethod) {
            return result.paymentMethod?.id;
          }
        }
      } else {
        if (result.paymentMethod) {
          return result.paymentMethod?.id;
        }
      }
      if (result.error) {
        this.$notify({
          group: "foo",
          title: result.error.message || "Something went wrong!",
          type: "error",
        });
        this.loading = false;
        return "error";
      }
    },
    handleCancel() {
      this.cardNumber.update({ value: "" });
      this.cardExpiry.update({ value: "" });
      this.cardCvc.update({ value: "" });
      this.isEditBilling = false;
    },
    openDialog(paymentMethodId) {
      this.dialog = true;
      this.paymentMethodId = paymentMethodId;
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .fld, .payment-method>.float-right{
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px){
  .payment-method{
    flex: 0 0 50%!important;
    max-width: 50%!important;
  }
}


</style>
